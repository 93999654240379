export const server_url = 'https://brightermind.in/'
export const Api_url = 'https://brightermind.in/administration/api/v1/user/front/'
export const imgUrl = 'https://brightermind.in/administration/public/'
export const blogImg = 'blog/'
export const gallerImg = 'gallery/'
export const testImg = 'testimonial/'
export const pdfUrl = 'https://brightermind.in/administration/public/stock/'

// export const server_url = 'http://65.1.107.90/'
// export const Api_url = 'http://65.1.107.90/administration/api/v1/user/front/'
// export const imgUrl = 'http://65.1.107.90/administration/public/'
// export const pdfUrl = 'http://65.1.107.90/administration/public/stock/'


// TARUN.KUMAR.FMS@GMAIL.COM
// APFPK6084G

// rzp_test_2MhE5wayJCEH6C

  // HFNVH3747H
  // hjdf@gmail.com


  // eg@gmail.com
  // REGHD4637G

  // ggb@gmail.com
  // TGNVB6765G