import React, { useEffect, useState } from "react";
import "./Style/dashboard.css";
import "../../App.css";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { useProfileInfoQuery } from "../../redux/API/portfolioApi";
import { useGetDataQuery } from "../../redux/API/dashboardApi";
import ReactPaginate from 'react-paginate';
import toast, { Toaster } from "react-hot-toast";
import {
  calculateCurretValue,
  calculateFundInvested,
  formatDate,
  formatIndianNumber,
} from "../../Constant/utils";
import DoughnutChart from "../Components/Card/DoughnutChart";
import BarChart from "../Components/Card/BarChart";
import PaymentModal from "../Components/Card/PaymentModal";
import { useNavigate } from "react-router-dom";

const DashBoard = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentDetails, setshowPaymentDetails] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [doughnutChartData, setDoughnutChartData] = useState({ labels: [], datasets: [] });
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const getProfile = useProfileInfoQuery();
  const { data, isLoading } = useGetDataQuery(userId);
  const [currentPage, setCurrentPage] = useState(0);
  const [profileData, setProfileData] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    if (getProfile.isSuccess && !getProfile.isError && !getProfile.isLoading) {
      localStorage.setItem("clientCode", getProfile?.data?.user?.clientcode)
      setProfileData(getProfile?.data)
      
    }
  }, [getProfile]);


  // Current Portfolio Value
  const curretValue = calculateCurretValue(data);

  // My Fund Invested
  const fundInvested = calculateFundInvested(data);


  // Absolute Return
  const result = ((curretValue - fundInvested) / fundInvested) * 100

  // Holding Period 
  const today = new Date(); 
  today.setHours(0, 0, 0, 0); // Ensure time part is zeroed
  let sumTransactions = 0;
  let totalWeight = 0;
  
  data?.investment?.forEach(item => {
      const transactionDate = new Date(item.date);
      transactionDate.setHours(0, 0, 0, 0); // Ensure time part is zeroed
      const transactionAmount = item.transacation;
  
      // Calculate the difference in years
      const timeDifferenceInYears = (today - transactionDate) / (365.25 * 24 * 60 * 60 * 1000); 
      
      // Calculate weight
      const weight = timeDifferenceInYears * transactionAmount;
  
      // Add to sum of positive transactions
      if (transactionAmount > 0) {
          sumTransactions += transactionAmount;
      }
      
      // Add to total weight
      totalWeight += weight;
  });
  
  // Calculate holding period in years
  const holdingPeriodInYears = totalWeight / sumTransactions;
  
  // Convert holding period to months
  const holdingPeriodInMonths = Math.round(holdingPeriodInYears * 12 * 10) / 10;



  useEffect(() => {
    if (data?.data) {
      const currentValue = data.data.reduce((acc, result) => {
        const currentprice = result?.stocklist[0]?.currentprice;
        return acc + (currentprice ? currentprice * result.qty : 0);
      }, 0);

      // Calculate the percentage of each industry in currentValue
      const industryPercentages = data.data.reduce((acc, result) => {
        const industry = result?.stocklist[0]?.industry;
        const currentprice = result?.stocklist[0]?.currentprice;
        const value = currentprice ? currentprice * result.qty : 0;

        if (industry) {
          if (!acc[industry]) {
            acc[industry] = 0;
          }
          acc[industry] += value;
        }

        return acc;
      }, {});

      // Convert industryPercentages to an array and calculate the percentage
      const industryArray = Object.keys(industryPercentages).map(
        (industry) => ({
          industry,
          percentage: Math.round((industryPercentages[industry] / currentValue) * 100),
        })
      );

      // Sort by currentprice and take the top 10
      const top10Industries = industryArray
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 10);

      // Prepare data for the chart
      const labels = top10Industries.map((item) => item.industry);
      const percentages = top10Industries.map((item) => item.percentage);

      // Set chart data
      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Weightage of Current Value",
            data: percentages,
            backgroundColor: "rgb(109, 214, 214)",
            borderColor: "rgb(109, 214, 214)",
            borderWidth: 1,
            barThickness: 25,
            maxBarThickness: 30, 
          },
        ],
      });
    }
  }, [data]);

  useEffect(() => {
    if (data?.data) {
      // Count the number of large cap and small cap stocks
      const capCount = data.data.reduce((acc, result) => {
        const marketCap = result?.stocklist[0]?.marketcapital;
        if (marketCap) {
          if (!acc[marketCap]) {
            acc[marketCap] = 0;
          }
          acc[marketCap] += 1;
        }
        return acc;
      }, {});

      // Calculate total count
      const totalCount = Object.values(capCount).reduce((acc, count) => acc + count, 0);

      // Calculate percentage
      const capPercentages = Object.keys(capCount).map(cap => ({
        label: `${cap} ${Math.round((capCount[cap] / totalCount) * 100)}%`,
        percentage: (capCount[cap] / totalCount) * 100,
      }));

      // Prepare data for the doughnut chart
      const labels = capPercentages.map(item => item.label);
      const percentages = capPercentages.map(item => item.percentage);

      // Set chart data
      setDoughnutChartData({
        labels: labels,
        datasets: [{
          data: percentages,
          backgroundColor: [
            '#ea7e90', 
            '#a84926',
            '#e28743'
          ],
          hoverOffset: 4
        }]
      });
    }
  }, [data]);


  // Fundflow pagination
  const itemsPerPage = 10;

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = data?.investment?.slice(offset, offset + itemsPerPage);


  // PaymentGateway
  const apiKey = process.env.REACT_APP_API_KEY;
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const investmentAmount = profileData?.investmentamount || 0;
  const advisoryFee = Math.round(investmentAmount * 1.25 / 100);
  const gstAmount =  Math.round(advisoryFee * 0.18);
  const totalAmount = Math.round(advisoryFee + gstAmount);
  
  
  const displayRazorpay = async () => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const advisoryFee = Math.round(profileData?.investmentamount * 1.25 / 100);
  const gstAmount = Math.round(advisoryFee * 0.18);
  const totalAmount = Math.round(advisoryFee + gstAmount);
    
    const options = {
      key: apiKey,
      amount: Math.round(totalAmount * 100),
      currency: "INR",
      name: "BRIGHTER MIND EQUITY ADVISORY",
      description: `Investment: ₹${profileData?.investmentamount}, Advisory Fee: ₹${advisoryFee}, GST: ₹${gstAmount}`,
      handler: function () {
       getProfile()
      },
      prefill: {
        name: profileData?.user?.userid?.name, 
        email:  profileData?.user?.userid?.email, 
        contact: profileData?.user?.userid?.phone,
      },
      notes: {
        investment_amount: `₹${profileData?.investmentamount}`,
        advisory_fee: `₹${advisoryFee.toFixed(2)}`,
        gst: `₹${gstAmount.toFixed(2)}`,
        total: `₹${totalAmount.toFixed(2)}`
      },
      theme: {
        color: "#3399cc", 
      },
    };



    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useEffect(() => {
    
    if (profileData && !profileData.user?.razrpaypaymentid) {
      setShowPayment(true);
    } else {
      setShowPayment(false);
    }
  }, [profileData]); 

  const handleClose = () => { 
    setshowPaymentDetails(false)
    setShowPayment(false)
  }

  return (
    <>
      <div className="App">
      <Toaster
        position="top-right"
        />
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="HomeCard">
            <div className="col-md mx-4">
              <div className="row ">
                <div className="col-xl-3 col-md-6 ">
                  <div className="card static-1 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">Current Portfolio Value</h5>
                      <h2 className="Values">
                       {
                          isLoading ? "Loading..." :  `Rs.${formatIndianNumber(Math.round(curretValue))}`

                       }
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6  ">
                  <div className="card static-2 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">My Fund Invested</h5>
                      <h2 className="Values">
                      {
                          isLoading ? "Loading..." :  `Rs.${formatIndianNumber(Math.round(fundInvested))}`

                       }
                        </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6  ">
                  <div className="card static-3 row-1 position-relative">
                    <span className="position-absolute bg-danger py-1 px-2 text-white rounded-2 new_feature">New</span>
                    <div className="mx-4">
                      <h5 className="card-title">Available Fund</h5>
                      <h2 className="Values" style={{fontSize: "14px"}}>
                      {/* {formatIndianNumber(data?.availablefund?.available)} */}
                      Coming Soon...
                       </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6  ">
                  <div className="card static-6 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">Total Profit</h5>
                      <h2 className="Values">
                      {
                          isLoading ? "Loading..." :  `Rs.${formatIndianNumber(Math.round(curretValue - fundInvested))}`

                       }
                        
                       </h2>
                    </div>
                  </div>
                </div>
               
                <div className="col-xl-3 col-md-6 pe-lg-0 ">
                  <div className="card static-4 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">CAGR</h5>
                      <h2 className="Values">
                      {
                          isLoading ? "Loading..." :  `${data?.CAGR?.CAGR !== "#NUM!"
                          ? Math.round(data?.CAGR?.CAGR)
                          : "---"}%`

                       }
                        
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 pe-lg-0">
                  <div className="card static-5 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">Absolute Return</h5>
                      <h2 className="Values">
                      {
                          isLoading ? "Loading..." :  `${Math.round(result)}%`

                       }
                        
                        </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6  pe-lg-0">
                  <div className="card static-3 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">Holding Period</h5>
                      <h2 className="Values">
                      {
                          isLoading ? "Loading..." :  `${holdingPeriodInMonths} Month`

                       }
                        
                        </h2>
                    </div>

                  </div>
                </div>
                <div className="col-xl-3 col-md-6  ">
                  <div className="card static-5 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">Account Opening Date</h5>
                      <h2 className="Values">
                      {
                          isLoading ? "Loading..." :  `${getProfile?.data?.user?.approvaldate ? formatDate(getProfile?.data?.user?.approvaldate) : formatDate(data?.investment[0]?.date)}`

                       }
                        
                        
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="PieChart-Table row gy-3 mx-4">
              <div className=" PieChart col-lg-8">
                <div className="row gy-3">
                  <div className="PieChartTitle d-flex m-2">
                    <h5>PortFolio Strategy: </h5>
                    <h5
                      style={{
                        backgroundColor: "#9DC913",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                      }}
                    >
                      {" "}
                      E-50 Strategy{" "}
                    </h5>
                  </div>
                  <div className="col-md-5">
                    <DoughnutChart 
                    doughnutChartData={doughnutChartData}
                    />
                  </div>
                  <div className="col-md-7 px-lg-1">
                    <BarChart chartData={chartData} />
                  </div>
                  <div
                    className="PieChartFooter mt-4"
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#78766f",
                    }}
                  >
                    <div className="text-white py-1" style={{backgroundColor: "#9DC913"}}>
                      No. of Stocks in Portfolio:{" "}
                     
                        <strong>{data?.data?.filter(item => item?.qty > 0).length}</strong>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="card DashBoardTable col-lg-4 py-3">
                <h4>Fund Inflow</h4>
                <MDBTable hover striped>
                  <MDBTableHead dark>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {data &&
                      currentItems?.sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((i, index) => (
                        <tr key={i?._id}>
                          <th scope="row">{offset + index + 1}</th>
                          <td>{formatDate(i?.date?.slice(0, 10))}</td>
                          <td>{formatIndianNumber(i?.transacation)}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                  {!isLoading && (data?.investment?.length === 0) && (
                          <MDBTableBody>
                            <tr>
                              <td className="text-center" colSpan={3}>No Record found</td>
                            </tr>
                          </MDBTableBody>
                        )}
                </MDBTable>
                {!isLoading && (data?.investment?.length > 0) && (
                <ReactPaginate
        previousLabel={"← Prev"}
        nextLabel={"Next →"}
        pageCount={Math.ceil(data?.investment?.length / itemsPerPage)}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
      />
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
      {showPayment && <PaymentModal setShowPayment={setShowPayment}  setshowPaymentDetails={setshowPaymentDetails}/>}
      {showPaymentDetails && 
       <div className="modal-wrapper">
       <div className="logout-confirm" style={{width: "400px"}}>
              <div className="title text-center mb-3">Payment Details</div>
              <p className="mb-1 text-black d-flex justify-content-between"><span>Investement Amount:</span>
              <span>Rs.{profileData?.investmentamount ? profileData?.investmentamount : '0'}</span>
              </p>
              <p className="mb-1 text-black d-flex justify-content-between"><span>Advisory Fee: <span style={{fontSize: '10px'}}>(1.25% of AVA for 6 months)</span></span> 
              <span>Rs.{advisoryFee}</span></p>
              <p className="mb-1 text-black d-flex justify-content-between"><span>GST:</span>
              <span>Rs.{gstAmount}</span>
              </p>
              <p className="mb-1 text-black d-flex justify-content-between"><span>Total:</span>
              <span>Rs.{totalAmount}</span>
              </p>
             
              <div className="bottom-buttons">
              <button  className='no'  onClick={handleClose} >Close</button>
                  <button className='yes' onClick={() => {
                    displayRazorpay();
                  }}>Make Payment</button>
              </div>
              </div>
  </div>
      }
    </>
  );
};

export default DashBoard;
