import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Api_url } from '../../front/constant/constant'



export const pageApi = createApi({
    reducerPath: "pageApi",
    baseQuery: fetchBaseQuery({baseUrl: `${Api_url}`}),
    endpoints: (builder) => ({
        testmonial: builder.query({
            query: () => ({
             url: "testimonial",
             method: "GET",
            })
         }),
         pages: builder.query({
            query: () => ({
             url: "pagelist",
             method: "GET",
            })
         }),
         pageDetails: builder.query({
            query: (id) => ({
             url: `pagedetail?pageid=${id}`,
             method: "GET",
            })
         }),
         complain: builder.query({
            query: () => ({
             url: `complainStatus`,
             method: "GET",
            })
         }),
         bannerContact: builder.mutation({
            query: (data) => ({
              url: `contact`,
              method: "POST",
              body: data,
            }),
          }), 
         
    })
})

export const { useTestmonialQuery, usePagesQuery, usePageDetailsQuery, useBannerContactMutation, useComplainQuery } = pageApi