import React, { useEffect } from "react";
import img1 from '../../images/stocks.svg'
import img2 from '../../images/Exposureicon.svg'
import img3 from '../../images/upper.svg'
import AOS from "aos";
import "aos/dist/aos.css";

const Offer = () => {

  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, 
    });
  }, []);



  return (
    <>
    <div className="py-5 mt-5" style={{backgroundColor: "rgba(243, 255, 211, 1)"}}>
      <div className="container mt-2 ">
        <h2 className="heading text-center ">Your Portfolio Structure</h2>
        <div className="row gy-3 mt-3 py-3 justify-content-center text-center">
          <div className="col-lg-4 col-md-6">
            <div className="offer-card rounded-2  position-relative text-white offer-card3 d-flex  flex-column  justify-content-end  align-items-center py-5 mx-auto" data-aos="fade-up">
              <img src={img1} alt="" width={35} className=" position-absolute top-0 start-0 ms-2 mt-3 z-2" />
              <h2>25-35</h2>
             <h6>Stocks</h6>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
          <div className="offer-card rounded-2 offer-card3 position-relative text-white d-flex  flex-column  justify-content-end align-items-center py-5 mx-auto" data-aos="fade-up">
          <img src={img2} alt="" width={35} className=" position-absolute top-0 mt-3 start-0 ms-2  z-2" />
              <h2>6-8 </h2>
             <h6>Sectoral Exposure</h6>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
          <div className="offer-card rounded-2 offer-card3 position-relative text-white d-flex  flex-column  justify-content-end  align-items-center py-5 mx-auto" data-aos="fade-up">
          <img src={img3} alt="" width={35} className=" position-absolute top-0 start-0 ms-2  mt-3 z-2"/>
              <h2>5-11-84</h2>
             <h6> Large-Mid-Small Cap</h6>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Offer;
