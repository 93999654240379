import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { cpbaseUrl } from "../../Constant/Matcher";



export const chanelPartnerApi = createApi({
    reducerPath: "chanelPartnerApi",
    baseQuery: fetchBaseQuery({baseUrl: `${cpbaseUrl}`}),
    endpoints: (builder) => ({
        clientDetail: builder.query({
            query: (userId) => ({
             url: `client?cpid=${userId}`,
             method: "GET",
            })
         }),
         
    })
})

export const { useClientDetailQuery } = chanelPartnerApi