import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useComplainQuery } from "../../../redux/API/pageApi";
import { server_url } from "../../constant/constant";
const MidBanner = () => {

  const currentDate = new Date();
  const lastMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
  const monthOptions = { month: 'long', year: 'numeric' };
  const formattedLastMonth = lastMonthDate.toLocaleDateString('en-US', monthOptions);

  const {data} = useComplainQuery()
  return (
    <>
      <div className="mid-banner position-relative d-flex align-items-center mb-4">
        <div className="container text-white  ">
          <div className="row  ">
            <div className="col-12">
              <div>
              
              </div>
              <h2 className="green-txt"><FiCheckCircle className="me-3" />INVESTMENT ADVISORY</h2>
              <h2 className=" text-white ms-5">Number of complaints - {formattedLastMonth}</h2>
            </div>
            <div className="col-12 my-4">
              <div className="row">
                <div className="col-lg-3 col-md-6"><p><strong className="fs-1 bg-transparent">{data?.inbeginingMonth ? data?.inbeginingMonth : 0}</strong><br/> In the Beginning of the month</p></div>
                <div className="col-lg-3 col-md-6"><p><strong className="fs-1 bg-transparent">{data?.receivedDuringMonth ? data?.receivedDuringMonth : 0}</strong><br/> Received During the month</p></div>
                <div className="col-lg-3 col-md-6"><p><strong className="fs-1 bg-transparent">{data?.resolvedDuringMonth ? data?.resolvedDuringMonth : 0}</strong><br/> Resolved during the month</p></div>
                <div className="col-lg-3 col-md-6"><p><strong className="fs-1 bg-transparent">{data?.pendingEndMonth ? data?.pendingEndMonth : 0}</strong><br/> Pending at the end of month</p></div>
              </div>
            </div>
            <div className="col-12">
              <p className="mb-1">View complete grievances status <a target="_blank" href={`${server_url}administration/${data?.uploadFile}`} rel="noreferrer" className="text-primary fw-bold fs-5 text-decoration-underline">here</a></p>
              <p>
                {" "}
                In case of any grievances or complaints please write to us on
                grievances@brightermind.in
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MidBanner;
