import React from 'react'
import { Link } from 'react-router-dom'


const AIFLogin = () => {
  return (
    <>
     <section className='container min-vh-100'>
        <div className="row min-vh-100">
            <div className="col-lg-12 d-flex flex-column align-items-center justify-content-center min-vh-100">
                <h1>COMING SOON...</h1>
                <p className="text-center mt-2">
                   We’re preparing something exceptional for you. <br />
                   Stay tuned for updates!
                    </p>
                 <Link to="/" className='login-btn text-white py-2 px-5 rounded-1 text-decoration-none mt-1'>HOME</Link>
            </div>
        </div>
     </section>
    </>
  )
}

export default AIFLogin