import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { baseUrl } from "../../Constant/Matcher";

const getToken = () => {
    const userToken = JSON.parse(localStorage.getItem("userData"))
    return userToken ? `Bearer ${userToken.token}` : ''
}
const clientCode = localStorage.getItem("clientCode")

export const portfolioApi = createApi({
    reducerPath: 'portfolioApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getToken()
            if(token) {
                headers.set('Authorization', token)
            }
            return headers;
        }
    }),
    endpoints : (builder) => ({
        portfolioList: builder.query({
            query:(clientCode) => ({
                url: `client/trasactionlist?clientcode=${clientCode}`,
                method:"GET",
            })
        }),
        transectionView: builder.query({
            query:(args) => {
                const { clientcode, stock} = args
                return {
                    url: `client/trasactiondetail?clientcode=${clientcode}&stock=${stock}`,
                    method:"GET",
                }
            }
        }),
        profileInfo: builder.query({
            query:() => ({
                url: `profile`,
                method:"GET",
            })
        }),
        qualitative: builder.query({
            query:(clientCode) => ({
                url: `client/qualitiveanalysis?clientcode=${clientCode}`,
                method:"GET",
            })
        }),
        portfoliodata: builder.query({
            query:(userId) => ({
                url: `client/review?userid=${userId}`,
                method:"GET",
            })
        }),
        performance: builder.query({
            query:() => ({
                url: `client/bestperformer`,
                method:"GET",
            })
        }),
    })
})

export const { usePortfolioListQuery, useTransectionViewQuery, useProfileInfoQuery, useQualitativeQuery, usePortfoliodataQuery, usePerformanceQuery }  = portfolioApi