import React, { useContext, useState } from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import "../Pages/Style/billsinvoice.css";
import "../Pages/Style/dashboard.css";
import "../../App.css";
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { SidebarContext } from '../../context/SidebarContext';
import { usePerformanceQuery } from '../../redux/API/portfolioApi';
import { formatDate, formatIndianNumber } from '../../Constant/utils';

const PerformanceSnapShot = () => {
const { isSiderbaropen } = useContext(SidebarContext);
const [showLogout, setShowLogout] = useState(false);
const role = "CP"

const {data} = usePerformanceQuery()



  return (
   <>
    <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" BillsInvoice  min-vh-100 ">
            {
              role === "franchise" && (
            <div className='mx-4 d-flex justify-content-end'>
              <select name="" id="" className='px-4 py-1 border-black-50'>
                <option value="">Select CP</option>
                <option value="">John Doe</option>
                <option value="">John Doe</option>
                <option value="">John Doe</option>
              </select>
            </div>
              )
            }
          <div className="PortfolioReviewHeader mx-4 my-4 py-2 px-2">
            <h4>Performance SnapShot</h4>
          </div>
          <div className="BillsInvoiceTable mx-4 overflow-y-hidden ">
              <MDBTable
                hover
                striped
                style={{
                  backgroundColor: "RGB(255 255 255)",
                  fontSize: "12px",
                }}
              >
                <MDBTableHead
                  style={{ backgroundColor: "#5A4344", color: "white" }}
                >
                  <tr>
                    <th scope="col">Client Name</th>
                    <th scope="col">Mobile No.</th>
                    <th scope="col">Email</th>
                    <th scope="col">Investment</th>
                    <th scope="col">Absolute Profit</th>
                    <th scope="col">Holding Value</th>
                    <th scope="col">Ltp Value</th>
                    <th scope="col">Account Opening Date</th>
                    <th scope="col">CAGR</th>
                    <th scope="col">View Detail</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {
                    data && data?.data?.map((i) => (
                      <tr >
                        <td className='blur-text '>{i?.clientid?.name}</td>
                        <td className='blur-text '>{i?.clientid?.phone}</td>
                        <td className='blur-text '>{i?.clientid?.email}</td>
                        <td>{formatIndianNumber(i?.investment)}</td>
                        <td>{""}</td>
                        <td>{formatIndianNumber(i?.holdingvalue)}</td>
                        <td>{formatIndianNumber(i?.ltpvalue)}</td>
                        <td>{formatDate(i?.date)}</td>
                        <td>{Math.round(i?.CAGR)}%</td>
                        <td>View</td>
                      </tr>
                       ))
                    }
                  
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
          <Footer/>
          </div>
          </div>
          {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
   </>
  )
}

export default PerformanceSnapShot