import React, { useEffect, useState } from "react";
import "../../Pages/Style/Login.css";
import "../../Pages/Style/otpScreen.css";
import Objectivecard from "./Objectivecard";
import Thirdform from "./Thirdform";
import Agreement from "./Agreement";
import SuccessModal from "../Card/SuccessModal";
import Question from "./Question";
import data from "../../../Constant/Question.json";
import {
  useGetRegisterOtpMutation,
  useRegisterationSubmitMutation,
  useVerifyDuplicateMutation,
} from "../../../redux/API/registerApi";
import { panRegex, aadharRegex } from "../../../Constant/utils";
import { Link, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Payment from "./Payment";
import axios from "axios";
const Registeration = () => {
  const [showFirstScreen, setShowFirstScreen] = useState(true);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [showThirdScreen, setShowThirdScreen] = useState(false);
  const [showFourthScreen, setShowFourthScreen] = useState(false);
  const [showFifthcreen, setShowFifthScreen] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showSucess, setShowSucess] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verified, setVerified] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [feildError, setFeildError] = useState("");
  const [userResponses, setUserResponses] = useState([]);
  const [score, setScore] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [referedBy, setReferedBy] = useState("");
  const [referById, setReferId] = useState("");
  const location = useLocation();
  const [signatureUrl, setSignatureUrl] = useState("");
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [razrpaypaymentid, setRazrPayPaymentId] = useState("");
  const [submitting, setSubmitting] = useState(false); 
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone: "",
    aadhar: "",
    panNumber: "",
    referred: "",
    correspondenceAddress: "",
    permanentAddress: "",
    investment: "",
    profile: "",
    panCard: "",
    aadharCard: "",
    cheque: "",
    signature: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referral = params.get("referral");
    const encodedUserId = params.get("refid");
    const userId = atob(encodedUserId);
    setReferedBy(referral || "");
    setReferId(userId || "");
  }, [location.search]);

  const handleFilechange = (e) => {
    let filename = e.target.files;
    setUserInfo({ ...userInfo, [e.target.name]: filename[0] });
  };

  const handleCheckboxChange = () => {
    setTermsChecked(!termsChecked);
  };

  const [verifyDuplicate] = useVerifyDuplicateMutation()
  //Logic for first form & to active second form
  const isAnyFieldNotEmpty =
    userInfo.name !== "" ||
    userInfo.email !== "" ||
    userInfo.phone !== "" ||
    userInfo.aadhar !== "" ||
    userInfo.panNumber !== "" ||
    userInfo.correspondenceAddress !== "" ||
    userInfo.permanentAddress !== "" ||
    userInfo.investment !== "";
  const handleShowForm = (e) => {
    e.preventDefault();
    if (isAnyFieldNotEmpty) {
      if (!panRegex.test(userInfo.panNumber)) {
        setFeildError("Invalid PAN Number*");
        setTimeout(() => {
          setFeildError("");
        }, 3000);
        return;
      }
      if (!aadharRegex.test(userInfo.aadhar)) {
        setFeildError("Invalid Aadhar Number*");
        setTimeout(() => {
          setFeildError("");
        }, 3000);
        return;
      }
      // Duplicate User Check
      verifyDuplicate({
        email: userInfo.email,
        phone: userInfo.phone,
        aadhar: userInfo.aadhar,
        PAN: userInfo.panNumber,
      }).then((res) => {
        if (res.data.duplicacyString === "User already exists") {
          setFeildError("User already exists");
          setTimeout(() => {
            setFeildError("");
            }, 5000);
            return;
            }
         else{
           setShowFirstScreen(false);
         setShowSecondScreen(true);
         setFeildError("");
         }
         })
         .catch((err) => {
         setFeildError("Something went wrong. Please try again");
         setTimeout(() => {
           setFeildError("");
         }, 3000);
    })
        }
      else {
      setFeildError("All Feilds are Required *");
      setTimeout(() => {
        setFeildError("");
      }, 3000);
    }
  };

  const handleAnswerChange = (questionIndex, selectedOption, score) => {
    const updatedResponses = [...userResponses];

    // Update existing response
    updatedResponses[questionIndex] = {
      question: data?.question[questionIndex]?.Question,
      answer: selectedOption,
      marks: score,
    };
    setUserResponses(updatedResponses);
    // console.log(updatedResponses);
  };

  // Function to get OTP
  const handleSubmit = () => {
    const postData = {
      otp: otp.join(""),
      phone: userInfo.phone,
    };
    postOtp(postData)
      .then((res) => {
        if (res?.error) {
          setOtpError("Incorrect OTP. Please try again.");
          setTimeout(() => {
            setOtpError("");
          }, 500);
        } else {
          setShowOtpScreen(true);
        }
      })
      .catch((error) => {
        // console.log("Something went wrong");
      });
  };
  // RESEND OTP
  const resendOtp = (e) => {
    e.preventDefault();
    const postData = {
      otp: otp.join(""),
      phone: userInfo.phone,
    };
    postOtp(postData);
  };

  // Function to verify OTP
  const [postOtp] = useGetRegisterOtpMutation();
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (otp.some((value) => value === "")) {
      setOtpError("Please fill in all OTP fields.");
      return;
    }
    const postData = {
      otp: otp.join(""),
      phone: userInfo.phone,
    };
    postOtp(postData)
      .then((res) => {
        if (res?.error) {
          setOtpError("Incorrect OTP. Please try again.");
          setTimeout(() => {
            setOtpError("");
          }, 3000);
        } else {
          setVerified(true);
        }
      })
      .catch((error) => {
        // console.log("Something went wrong");
      });
  };

  const handleDocumentSubmit = (e) => {
    e.preventDefault();
    if (!termsChecked) {
      setFeildError("Please agree to the terms & conditions");
      setTimeout(() => {
        setFeildError("");
      }, 3000);
      return;
    }
    if (
      userInfo.profile !== "" ||
      userInfo.aadharCard !== "" ||
      userInfo.panCard !== "" ||
      userInfo.signature !== ""
    ) {
      setShowThirdScreen(false);
      setShowFourthScreen(true);
      const signatureUrl = URL.createObjectURL(userInfo.signature);
          setSignatureUrl(signatureUrl);
          // console.log(signatureUrl);
    } else {
      setFeildError("Please Upload documents");
      setTimeout(() => {
        setFeildError("");
      }, 3000);
    }
  };

   // Function for Register Now Completion
   const [submitData, {isLoading}] = useRegisterationSubmitMutation();
 

   const apiKey = process.env.REACT_APP_API_KEY;
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const advisoryFee = Math.round(userInfo.investment * 1.25 / 100);
  const gstAmount = Math.round(advisoryFee * 0.18);
  const totalAmount = Math.round(advisoryFee + gstAmount);


  const displayRazorpay = async () => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const advisoryFee = Math.round(userInfo.investment * 1.25 / 100);
  const gstAmount = Math.round(advisoryFee * 0.18);
  const totalAmount = Math.round(advisoryFee + gstAmount);
    
    const options = {
      key: apiKey,
      amount: Math.round(totalAmount * 100),
      currency: "INR",
      name: "BRIGHTER MIND EQUITY ADVISORY",
      description: `Investment: ₹${userInfo.investment}, Advisory Fee: ₹${advisoryFee}, GST: ₹${gstAmount}`,
      handler: function (response) {
        setRazrPayPaymentId(response.razorpay_payment_id)
        setPaymentCompleted(true)
        handleRegistration(response.razorpay_payment_id);
      },
      prefill: {
        name: userInfo.name, 
        email:  userInfo.email, 
        contact: userInfo.phone,
      },
      notes: {                           
        investment_amount: `₹${userInfo.investment}`,
        advisory_fee: `₹${advisoryFee.toFixed(2)}`,
        gst: `₹${gstAmount.toFixed(2)}`,
        total: `₹${totalAmount.toFixed(2)}`
      },
      theme: {
        color: "#3399cc", 
      },
    };



    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleRegistration = async (razorpayPaymentId) => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append("name", userInfo.name);
    formData.append("email", userInfo.email);
    formData.append("phone", userInfo.phone);
    if (razorpayPaymentId && typeof razorpayPaymentId === "string") {
      formData.append("razrpaypaymentid", razorpayPaymentId);
    } 
    formData.append("address", userInfo.correspondenceAddress);
    formData.append("permanentAddress", userInfo.permanentAddress);
    formData.append("investmentamount", userInfo.investment);
    formData.append("PAN", userInfo.panNumber);
    formData.append("aadhar", userInfo.aadhar);
    formData.append("profileimg", userInfo.profile);
    formData.append("aadharCard", userInfo.aadharCard);
    formData.append("cheque", userInfo.cheque);
    formData.append("signature", userInfo.signature);
    formData.append("panCard", userInfo.panCard);
    formData.append("password", userInfo.panNumber);
    formData.append("totalscore", score);
    formData.append("riskProfile", JSON.stringify(userResponses));
    
    // console.log("FormData entries:");
    // for (const entry of formData.entries()) {
    //   console.log(entry);
    // }
      try {
        // Perform your mutation here
        const res = await submitData(formData);
        
        if (res?.data?.message === "Registration successful") {
          setShowOtpScreen(false);
          setShowFourthScreen(false);
          setShowFifthScreen(false)
          setShowSucess(true);
          // console.log(res?.data?.message);
        } else {
          setFeildError("Something went wrong, please try later");
          setTimeout(() => {
            setFeildError("");
          }, 3000);
        }
      } catch (error) {
        console.log("Something went wrong");
      } finally {
        setSubmitting(false);
      }
  };

 
  return (
    <>
     <Toaster
        position="top-right"
        />
      <div className="sign-up">
        <div className="steps">
          <div className="step-arrow"></div>
          <div className="step-area">
            <span className={!showFirstScreen ? "box" : "box active-box"}>
              1
            </span>{" "}
            <span>Step 1</span>
          </div>
          <div className="step-area">
            <span className={!showSecondScreen ? "box" : "box active-box"}>
              2
            </span>{" "}
            <span>Step 2</span>
          </div>
          <div className="step-area">
            <span className={!showQuestion ? "box" : "box active-box"}>3</span>{" "}
            <span>Step 3</span>
          </div>
          <div className="step-area">
            <span className={!showThirdScreen ? "box" : "box active-box"}>
              4
            </span>{" "}
            <span>Step 4</span>
          </div>
          <div className="step-area">
            <span
              className={
                !showFourthScreen && !showFifthcreen ? "box" : "box active-box"
              }
            >
              5
            </span>{" "}
            <span>Final Step</span>
          </div>
        </div>
        <p className="intro-para">
          "Welcome to our registration process! To ensure you have a smooth and
          personalized experience on our platform, please complete the following
          steps before accessing your dashboard."
        </p>
        { showFirstScreen && (
          <>
            <div className="first-screen">
              <form className="form register">
                <div className="title text-center">Register Now</div>
                <p className="text-danger mb-0 text-center">{feildError}</p>
                <div className="row justify-content-evenly">
                  <div className="col-6">
                    <div className="input-container ic1">
                      <input
                        required
                        placeholder="Name"
                        type="text"
                        className="input"
                        id="name"
                        name="name"
                        value={userInfo.name}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder="Email"
                        type="email"
                        className="input"
                        id="email"
                        name="email"
                        value={userInfo.email}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder="Mobile"
                        type="text"
                        className="input"
                        id="phone"
                        name="phone"
                        maxLength={10}
                        value={userInfo.phone}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, phone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder="PAN Number"
                        type="text"
                        className="input"
                        id="panNumber"
                        name="panNumber"
                        value={userInfo.panNumber}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            panNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder="Aadhar Number"
                        type="text"
                        className="input"
                        id="aadhar"
                        name="aadhar"
                        maxLength={12}
                        value={userInfo.aadhar}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, aadhar: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder="Investment Amount"
                        type="text"
                        className="input"
                        id="investment"
                        name="investment"
                        value={userInfo.investment}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            investment: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder=" Correspondence Address"
                        type="text"
                        className="input"
                        id="correspondenceAddress"
                        name="correspondenceAddress"
                        value={userInfo.correspondenceAddress}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            correspondenceAddress: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder=" Permanent Address"
                        type="text"
                        className="input"
                        id="permanentAddress"
                        name="permanentAddress"
                        value={userInfo.permanentAddress}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            permanentAddress: e.target.value,
                          })
                        }
                      />
                    </div>
                   
                  </div>
                  <div className="col-12">
                    <div className="input-container ic2">
                      <input
                        required
                        placeholder=" Referred By"
                        type="text"
                        className="input"
                        id="referred"
                        name="referred"
                        value={referedBy || userInfo.referred}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, referred: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="submit"
                  type="submit"
                  onClick={handleShowForm}
                >
                  Proceed
                </button>
                <p className="mt-2 text-center">
                  Already Registered? <Link to="/login" className=' text-primary text-decoration-underline'>Login</Link>{" "}
                </p>
              </form>
            </div>
          </>
        )}
        {showSecondScreen && (
          <Objectivecard
            setShowSecondScreen={setShowSecondScreen}
            setShowQuestion={setShowQuestion}
          />
        )}
        {showQuestion && (
          <Question
            setShowQuestion={setShowQuestion}
            setShowThirdScreen={setShowThirdScreen}
            handleAnswerChange={handleAnswerChange}
            userResponses={userResponses}
            setScore={setScore}
          />
        )}
        {showThirdScreen && (
          <Thirdform
            handleFilechange={handleFilechange}
            handleRegistration={handleRegistration}
            feildError={feildError}
            handleDocumentSubmit={handleDocumentSubmit}
            score={score}
            termsChecked={termsChecked}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
        {showFourthScreen && (
          <Agreement
            setShowFourthScreen={setShowFourthScreen}
            setShowFirstScreen={setShowFirstScreen}
            setShowFifthScreen={setShowFifthScreen}
            setShowSucess={setShowSucess}
            setShowOtpScreen={setShowOtpScreen}
            handleOtpSubmit={handleOtpSubmit}
            handleRegistration={handleRegistration}
            handleSubmit={handleSubmit}
            showOtpScreen={showOtpScreen}
            verified={verified}
            otpError={otpError}
            otp={otp}
            setOtp={setOtp}
            feildError={feildError}
            resendOtp={resendOtp}
            name={userInfo.name}
            panNumber={userInfo.panNumber}
            address={userInfo.correspondenceAddress}
            submitting={submitting}
            displayRazorpay={displayRazorpay}
            paymentCompleted={paymentCompleted}
            advisoryFee={advisoryFee}
            gstAmount={gstAmount}
            totalAmount={totalAmount}
            invamount={userInfo.investment}
            formData={{
              signatureUrl: signatureUrl
            }}
           
          />
        )}
        {
          showFifthcreen && (
        <Payment
        advisoryFee={advisoryFee}
        gstAmount={gstAmount}
        totalAmount={totalAmount}
        invamount={userInfo.investment}
        submitting={submitting}
        displayRazorpay={displayRazorpay}
        handleRegistration={handleRegistration}
        /> 
        ) }
        {
          isLoading && (
        
        <div className="submit-modal position-fixed top-0 start-0 w-100">
        <div class="submit-loader">Processing...</div>
        </div>
            
          )
        }
        {showSucess && <SuccessModal />}
      </div>
    </>
  );
};

export default Registeration;
