import React from 'react'
import Banner from '../components/Home/Banner'
import About from '../components/Home/About'
import Winner from '../components/Home/Winner'
import Whyus from '../components/Home/Whyus'
import Offer from '../components/Home/Offer'
import MidBanner from '../components/Home/MidBanner'
import Blog from '../components/Home/Blog'
import LastBanner from '../components/Home/LastBanner'
import Testimonial from '../components/Home/Testimonial'
import Strategy from '../components/Home/Strategy'
import  '../style/style.css'
import Navbar from '../components/partial/Navbar'
import Footer from '../components/partial/Footer'
import Multibagger from '../components/Home/Multibagger'
import { Helmet } from 'react-helmet-async'

const Home = () => {

  



  return (
    <>
     <Helmet>
        <title>SEBI Registered Equity Investment Advisor - Brighter Mind</title>
        <meta name="description" content="SEBI Registered Investment Advisor - Brighter Mind Provides Investment advisory services to the Investors. With 100% Customer Support, 100% Transparency to the Investors." />
      </Helmet>
    <Navbar/>
    <Banner/>
    <About/>
    <Winner/>
    {/* <Multibagger/> */}
    <Whyus/>
    <Offer/>
    <Strategy/>
    <Testimonial/>
    <MidBanner/>
    <Blog/>
    <LastBanner/>
    <div className="container m-auto h-100 ">
       
       <div className="row">
         <div className="col-12">
         <div className="table-responsive">
             <table className="table table-bordered border-black detail_table detail_table_two">
               <thead>
                 <tr>
                   <th  className="contact-header text-white fw-semibold">
                   Details of designation
                   </th>
                   <th  className="contact-header text-white fw-semibold">
                   Contact Person Name
                   </th>
                   <th  className="contact-header text-white fw-semibold">
                   Address where the physical address location
                   </th>
                   <th  className="contact-header text-white fw-semibold">
                   Contact No.
                   </th>
                   <th className="contact-header text-white fw-semibold">
                   Email-ID
                   </th>
                   <th  className="contact-header text-white fw-semibold">
                   Working hours when complainant can call
                   </th>
                 </tr>
               </thead>
               <tbody>
                <tr>
                  <td>Customer Care</td>
                  <td>Prathmesh Shende</td>
                  <td>305, T 4, Assotech Business Cresterra, Sec-135, Noida–201304</td>
                  <td>9911005780</td>
                  <td>grievances@brightermind.in</td>
                  <td>9:45 – 6:45</td>
                </tr>
                <tr>
                  <td>Head of Customer Care</td>
                  <td>Sanket Borgaonkar</td>
                  <td> 305, T 4, Assotech Business Cresterra, Sec-135, Noida-201304	</td>
                  <td>8999463307</td>
                  <td>sanket@brightermind.in</td>
                  <td>9:45 - 6:45</td>
                </tr>
                <tr>
                  <td>Compliance Officer</td>
                  <td>Shashi Kant</td>
                  <td>305, T 4, Assotech Business Cresterra, Sec-135, Noida–201304</td>
                  <td>9911005770</td>
                  <td>shashikant@brightermind.in</td>
                  <td>9:45 - 6:45</td>
                </tr>
                <tr>
                  <td>CEO</td>
                  <td>Manoj Bandre</td>
                  <td>305, T 4, Assotech Business Cresterra, Sec-135, Noida–201304</td>
                  <td>9890022039</td>
                  <td>manojbandre@brightermind.in</td>
                  <td>9:45 - 6:45</td>
                </tr>
                <tr>
                  <td>Principal Officer</td>
                  <td>Shashi Kant</td>
                  <td>305, T 4, Assotech Business Cresterra, Sec-135, Noida–201304</td>
                  <td>9911005770</td>
                  <td>shashikant@brightermind.in</td>
                  <td>9:45 - 6:45</td>
                </tr>
               </tbody>
             </table>
           </div>
         </div>
       </div>
       </div>
    <Footer/>
    </>
  )
}

export default Home