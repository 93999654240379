import React, { useRef, useState } from 'react'
import '../Pages/Style/Login.css'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { ImArrowRight2 } from "react-icons/im";
import { useDispatch } from 'react-redux'
import { useProfileInfoQuery } from '../../redux/API/portfolioApi'
import { useLoginSubmitMutation } from '../../redux/API/registerApi'
import { loginSuccess } from '../../redux/reducer/reducer';
const CPLogin = () => {
    const [inputVal, setInputVal] = useState({
        userId: "",
        password: "",
      })
      const [error, setError] = useState("")
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const handleChange = (e) => {
        const {name, value} = e.target
        setInputVal({...inputVal, [name]: value})
      }

      const [postLogin ] = useLoginSubmitMutation() 
const getProfile = useRef(useProfileInfoQuery());
const handleSubmit = (e) => {
  e.preventDefault()
  if(inputVal.userId === "" || inputVal === ""){
    setError("Please enter your Email & Password")
    setTimeout(() => {
      setError("")
    }, 4000);
    return;
  }
  const data = {
    email : inputVal.userId,
    password: inputVal.password
  }
  postLogin(data).then((res) => {
    if(res?.data?.success === true && res?.data?.message !== "Invalid email"){
      dispatch(loginSuccess(res?.data))
      localStorage.setItem("userData", JSON.stringify(res?.data))
        navigate('/CP/dashboard')
      getProfile.current.refetch()
      if (getProfile.isSuccess && !getProfile.isError && !getProfile.isLoading) {
        localStorage.setItem("clientCode", getProfile?.data?.user?.clientcode);
      } else {
        console.log("Something went wrong");
        
      }
      
    }
    else if(res?.data?.message === "Invalid Password"){
      setError(res?.data?.message)
      setTimeout(() => {
        setError("")
      }, 5000);
    }
    else if(res?.data?.message === "Invalid email"){
      setError(res?.data?.message)
      setTimeout(() => {
        setError("")
      }, 5000);
    }
    else{
      setError("Something went wrong, please try again later")
      setTimeout(() => {
        setError("")
      }, 5000);
    }
  })
  .catch((error) => {
    console.log(error);
  })
  
}

  return (
    <>
    <div className="login">
    <div className="login-container position-relative">
      <NavLink to="/login" className="position-absolute top-0 end-0 text-primary text-decoration-underline me-2"><span className='me-1'><ImArrowRight2 /></span>Client Login</NavLink>
    <div className="heading mt-2">CP Login</div>
    <p className="text-danger text-center mb-1">{error}</p>
    <form action="" className="form" onSubmit={handleSubmit}>
      <input  className="input" type="email" name="userId" id="userId" placeholder="Enter Email" value={inputVal.userId} onChange={handleChange}/>
      <input  className="input" type="password" name="password" id="password" placeholder="Enter Password" value={inputVal.password} onChange={handleChange}/>
      <span className="forgot-password"><Link >Forgot Password ?</Link></span>
      <input className="login-button" type="submit" value="Sign In"/>
    </form>
  </div>
  </div>
    </>
  )
}

export default CPLogin